const cloudFunctionsUrl = 'https://us-central1-woden-dev.cloudfunctions.net';
const apiUrl = cloudFunctionsUrl + '/api';
const apiAuthUrl = apiUrl + '/auth';
const apiEmailUrl = apiUrl + '/emails';
const apiBigQuerylUrl = apiUrl + '/bigquery';
const wodenUrl = 'https://dev.wodenevents.com';

export const environment = {
  production: true,

  version: require('../../package.json').version,
  wodenUrl: wodenUrl,

  rsvpNotGoing: wodenUrl + '/emailRSVPResponse?newState=notGoing',
  rsvpAcceptFree: wodenUrl + '/emailRSVPResponse?newState=registered',

  gMapsApiKey: 'AIzaSyCq6HXCNZMny39Vl6DYpODPTElkvt6S1OM&',

  stripeApiKey: 'pk_test_dTD2BCKFinXdc6def6mtQ77n00orAqccYQ',

  bucketServer: 'woden-dev.appspot.com',

  oauth: {
    google: {
      clientId: '158024523272-4hbbdb6r2gc1hjcc85k6hrc5ra5e6dg8.apps.googleusercontent.com'
    }
  },

  api: {
    url: apiUrl,
    emailUrl: apiEmailUrl + '/show',
    rsvpEmailUrl: apiEmailUrl + '/rsvp',
    createTokenUrl: apiAuthUrl + '/createToken',
    invitationEmailUrl: apiEmailUrl + '/invitation',
    googleOAuthCode: apiAuthUrl + '/googleOAuthCode',
    generateAPIKeyUrl: apiAuthUrl + '/generateAPIKey',
    getLocationOffsetUrl: apiUrl + '/utc/locationOffset',
    addRegistrantsListUrl: apiUrl + '/registrantLists/events/:showId',
    stripeSessionIdUrl: apiUrl + '/payments/newPaymentMethodSessionId',
    bigQueryRegistrantsCountAndPaidSumByDateUrl: apiBigQuerylUrl + '/registrantsSalesByShow'
  },

  chiketto: {
    apiKey: '7zevawp5fb.1e3cf7e0-cf52-4033-ba7f-8258ad1d1cb0',
    chikettoPostImageUrl: 'https://us-central1-dev-chiketto.cloudfunctions.net/api/images'
  },
  // pm_1HCaibI6e5g28ePSI1fnLiHN
  /**Firebase for Woden development */
  firebase: {
    apiKey: 'AIzaSyBsgjucL59raMdYfruI2P1NZs5v0DaUuR0',
    authDomain: 'woden-dev.firebaseapp.com',
    databaseURL: 'https://woden-dev.firebaseio.com',
    projectId: 'woden-dev',
    storageBucket: 'woden-dev.appspot.com',
    messagingSenderId: '158024523272'
  }

  /**Firebase for testing purposes */
  // firebase: {
  //   apiKey: "AIzaSyDrwNl5dKjARvn0VdNqho_lPB5sIxas2mU",
  //   authDomain: "fir-testing-c6c46.firebaseapp.com",
  //   databaseURL: "https://fir-testing-c6c46.firebaseio.com",
  //   projectId: "fir-testing-c6c46",
  //   storageBucket: "fir-testing-c6c46.appspot.com",
  //   messagingSenderId: "612253561892"
  // }
};
